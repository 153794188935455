import $ from 'jquery';
import 'jquery-validation';

/**
 * Used to handle all form requests
 *
 * 1) ajax
 * 2) offline
 * 3) upload pictures
 */
export let FORM = {

    // current ajax status
    AJAX: false,

    /**
     * Start the form in motion
     */
    start: function(options)
    {
        // defaults
        options = options || {};

        // setup defaults for form
        if( !options.formId ) {
            options.formId = 'form';
        }

        var data = ( options.hasOwnProperty('data') )  ? options.data : {};

        // setup data
        this.DATA = data;

        // 1. SETUP DOM
        app.FORM.setup_dom(options);

        // 2. SETUP EVENTS
        app.FORM.setup_events(options);

        // if form has been redirected to success display success messge
        if(app.URI[2] === 'success' && !options.hideConfirmation &&
            app.TBL[app.HASH.replace('_form','')] && app.TBL[app.HASH.replace('_form','')].i
        ) {
            this.success_valid({'msg': app.TBL[app.HASH.replace('_form','')].i + ' has been added succesfully.'}, false, true);
        }
    },

    /**
     * Start Filter Form in motion
     */
    start_filter: function(options)
    {
        app.VIEW[app.HASH].DOM.filter = app.DOM.content_load.find('#filter').show();
        app.VIEW[app.HASH].DOM.filter_i = app.VIEW[app.HASH].DOM.filter.find('#filter-icon');
        app.VIEW[app.HASH].DOM.filter_input = app.VIEW[app.HASH].DOM.filter.find('#filter-input');
        app.VIEW[app.HASH].DOM.filter_table = app.DOM.content_load.find('#filter-table');
        app.VIEW[app.HASH].DOM.bottom_container = app.DOM.content_load.find('.button-left-right-fixed');

        // FILTER
        app.VIEW[app.HASH].DOM.filter_input.table_filter(
            {'table': app.VIEW[app.HASH].DOM.filter_table.find('tbody')}
        );

        // CHANGE
        app.VIEW[app.HASH].DOM.filter_input.on('keyup', function(){
            if( app.VIEW[app.HASH].DOM.filter_input.val() === '' ) {
                app.VIEW[app.HASH].DOM.filter_i.addClass('fa-search').removeClass('fa-times');
            } else {
                app.VIEW[app.HASH].DOM.filter_i.removeClass('fa-search').addClass('fa-times');
            }
        });

        // ICON: CLICK
        app.VIEW[app.HASH].DOM.filter_i.on('click', function(){
            if( $(this).hasClass('fa-search') ) {
                return;
            }

            // clear search
            app.VIEW[app.HASH].DOM.filter_input.val('').trigger('keyup');

            // reset icon
            app.VIEW[app.HASH].DOM.filter_i.addClass('fa-search').removeClass('fa-times');
        }).trigger('click');

        // PREVENT DEFAULT
        app.VIEW[app.HASH].DOM.filter.on('submit', function(e){ e.preventDefault();});

        // HACKFIX
        app.hack_fix(app.VIEW[app.HASH].DOM.bottom_container, options);

        // ROWS LINKS?
        if( options && options.row_click ) {
            setTimeout(app.FORM._filter_row_click, 100);
        }
    },

    _filter_row_click: function()
    {
        // DOM
        app.VIEW[app.HASH].DOM.filter_table_tr = app.VIEW[app.HASH].DOM.filter_table.find('tbody tr[data-href]');

        // EVENT
        app.VIEW[app.HASH].DOM.filter_table_tr.on('click', function(){
            app.redirect($(this).attr('data-href'));
        });
    },

    setup_dom: function(options)
    {
        // get current dom element
        var DOM = {};

        // build dom object
        DOM.form = app.DOM.content_load.find(`form#${options.formId}`);

        // form buttons
        DOM.bottom_container =  DOM.form.find('div#bottom-container');
        DOM.btns =  DOM.bottom_container.find('div#form-btns');
        DOM.form_btn_submit = DOM.btns.find('#submit');
        DOM.form_btn_back = DOM.btns.find('#btn-back');

        // setup 2 different form types
        if( options.inline ) {
            DOM.form_id = DOM.form.find('#id');
            DOM.form_action = DOM.form.find('#action');
            DOM.form_tbl = DOM.form.find('#tbl');
            DOM.form_ts = DOM.form.find('#ts');
        } else {

            // prepend the prepend default .msg
            var prepend = ( options.prepend ) ? options.prepend : '';

            if( options.msg_insert !== false ) {
                DOM.form.prepend(prepend+'<p class="msg hide"></p>');
            }

            DOM.form.find('#form-btns').before('<img width="25" id="form-loading" src="img/loading-black.svg"">');
            DOM.msg = DOM.form.find('p.msg');
            DOM.loading = DOM.form.find('div#form-loading');
        }

        // RANGESLIDER
        if( options.range ) {
            DOM.range = DOM.form.find('input[type="range"]');
        }

        // PIN
        if( options.pin ) {
            DOM.pins = DOM.form.find('p input.pin');
        }

        // CHECK AJAX STATUS
        // hide and inform user
        if( app.HASH !== 'login' && app.check_ajax() ){
            DOM.bottom_container.before('<p class="red" id="no-save"><i class="fa fa-info-circle"></i> Sorry you can\'t add/edit any items while the app is syncing, please wait until it has finished and reload the page.<br><br> When the app has finished syncing the spinning arrows in the status bar should disappear.</p>');
        }

        // UPDATE VIEW DOM
        app.VIEW[app.HASH].DOM = $.extend({}, app.VIEW[app.HASH].DOM, DOM);
    },

    /**
     * Apply jquery validation on the form
     *
     * @param {string} page
     */
    setup_events: function(options)
    {
        // PREVENT FORM SUBMISSION
        app.VIEW[app.HASH].DOM.form.on('submit', function(e) {
            e.preventDefault();
        });

        // SUBMIT WITH A.BUTTON
        app._('form_btn_submit').on('click', function(e){

            // cancel default
            e.preventDefault();

            // disabled form submission
            if( app.DOM.footer.is(':visible') && app.DOM.footer_btn_right.hasClass('button-grey') ) {
                return;
            }

            // submit form
            app.VIEW[app.HASH].DOM.form.submit();
        });

        // VALIDATE
        app.FORM.setup_validate(options);

        // range slider
        if( options.range ) {
            app.FORM.setup_range();
        }

        // setup pin events
        if( options.pin ) {
            app.FORM.setup_pin();
        }

        // form changes
        if( options.hasOwnProperty('changes') === false || options.changes  ) {
            this.setup_changes(options);
        }
    },

    setup_changes: function(options)
    {
        // set change status to off
        app.FORM.changes('off', options);

        // original form data without changes
        app.VIEW[app.HASH].FORM_DATA_ORIG = app.VIEW[app.HASH].DOM.form.serialize();
    
        // ch-ch-ch-changes
        app.VIEW[app.HASH].DOM.form.find(':input').change(function(){
             
            // check change
            if( app.VIEW[app.HASH].FORM_DATA_ORIG === app.VIEW[app.HASH].DOM.form.serialize() ) {
                // no changes
                app.FORM.changes('off', options);
            } else {
                // changes
                app.FORM.changes('on', options);
            }
        });
         
        // click back
        app.VIEW[app.HASH].DOM.form_btn_back.on('click', function(e){

            e.preventDefault();

            // check if redirect is allowed
            app.allowed_redirect('back_form');
        });
    },

    changes: function(action, options)
    {
        if( action === 'on' ) {
            // changes have been made
            app.VIEW[app.HASH].UNSAVED = true;
            app.DOM.header_title.find('#unsaved').text(' *');
            app.VIEW[app.HASH].DOM.form_btn_submit.show();

        } else if( action === 'off' ) {

            // changes have been reset to defaults
            app.VIEW[app.HASH].UNSAVED = false;
            app.DOM.header_title.find('#unsaved').text('');


            // hide save button
            if( !options.save_show ){
                app.VIEW[app.HASH].DOM.form_btn_submit.hide();
            }
        }
    },

    /**
     * Requires a.button to have
     *
     * data-id/data-ts=""
     * data-tbl=""
     * data-action=""
     */
    inline: function($btn, $row, data_extra)
    {
        app.VIEW[app.HASH].DOM.form_action.val( $btn.attr('data-action') );
        app.VIEW[app.HASH].DOM.form_tbl.val( $btn.attr('data-tbl') );

        // id
        if( $btn.attr('data-id') ) {
            app.VIEW[app.HASH].DOM.form_id.val( $btn.attr('data-id') );
        } else {
            app.VIEW[app.HASH].DOM.form_id.val('');
        }
 
        // timestamp
        if( $btn.attr('data-ts') ) {
            app.VIEW[app.HASH].DOM.form_ts.val( $btn.attr('data-ts') );
        } else {
            app.VIEW[app.HASH].DOM.form_ts.val('');
        }

        // extra data-* params
        var data = $.extend({}, $btn[0].dataset );         

        // remove unwanted attr we already processed them
        delete data.action; delete data.id; delete data.tbl;

        // update other data attritbutes
        $.each(data,function(k,v){

            var id = 'form_'+k;

            // check dom item exist
            if( app.VIEW[app.HASH].DOM.hasOwnProperty(id) ) {
                app.VIEW[app.HASH].DOM[id].val(v);
            } else {
                console.warn('No app.VIEW.'+app.HASH+'.DOM.'+id);
            }
        });

        // update action url
        var url = ( $btn.attr('data-id') !== undefined && $btn.attr('data-id') !== '' ) ? $btn.attr('data-tbl') + '/'+$btn.attr('data-action')+'/' + $btn.attr('data-id') : '';

        app.VIEW[app.HASH].DOM.form.attr('action', url);

        // submit form
        app.VIEW[app.HASH].DOM.form_btn_submit.click();

        // hide parent $row
        if( $row !== undefined ) {
            setTimeout(function(){ $row.hide();}, 200 );
        }
    },

    /**
     * Apply rangeSlider plugin to any input[range]
     */
    setup_range: function()
    {
        // go range!
        app.VIEW[app.HASH].DOM.range.rangeslider({
            polyfill: false,
            onSlide: function(pos,val){                 

                var $text = this.$element.prev(),
                    format =( $text.attr('data-format') ) ? $text.attr('data-format') : '';                     

                // update text
                $text.html( val + format );
            },

            onSlideEnd: function(pos, val)
            {
                if( app.VIEW[app.HASH].hasOwnProperty('onSlideEnd') ) {
                    app.VIEW[app.HASH].onSlideEnd(val);
                }
            }
        });
    },

    setup_pin: function()
    {
        // Dont allow user to enter any spaces numeric only
        app.VIEW[app.HASH].DOM.pins.keypress(function(e){

            if(e.which === 32) {
                return false;
            }

            // submit form
            if( app.VIEW[app.HASH].DOM.pins.val().length === 3 && (e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode == 8 ) {
                setTimeout(function(){ app.VIEW[app.HASH].DOM.form_btn_submit.click(); }, 50);
            }

            return ( (e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode == 8 );
        });
    },

    setup_validate: function(options)
    {
        // VALIDATE
        app.VIEW[app.HASH].DOM.form.validate({
            'rules': ( options.rules ) ? options.rules : false,
            'ignore': [],
            'submitHandler': function(e){ app.FORM.submit(e, options); },
            'errorPlacement': function(){},
            'highlight': function(element) {
                $(element).addClass('error');
                $(element).parent().addClass('error');
            },
            'unhighlight': function(element) {
                $(element).removeClass('error');
                $(element).parent().removeClass('error');
            }
        });

        // allow hidden fields as required
        if( app.VIEW[app.HASH].DOM.form.length > 0 ) {
            $.data(app.VIEW[app.HASH].DOM.form[0], 'validator').settings.ignore = "";
        }
    },

    /**
     * Process the form
     */
    submit: function(e, options)
    {
        // CHANGE FORM STATUS
        app.FORM.changes('off', options);

        // UPDATE DOM
        app.FORM.loading();
        app.DOM.footer_centre.text('');

        // RESET FORM ORIG
        app.VIEW[app.HASH].FORM_DATA_ORIG = $(e).serialize();

        let action = app.VIEW[app.HASH].DOM.form.attr('action');
        let data;

        // FORM DATA
        if( action === '' ) {
            data = $(e).serializeObject();
        } else {
            data = app.get_api_data( $(e).serializeObject() );
        }

        // order is import that form data overwrites stored data
        data = $.extend({}, app.FORM.DATA, data);
        
        // set tbl action
        if( data.action ) {
            app.VIEW[app.HASH].ACTION = data.action;
        } else {
            app.VIEW[app.HASH].ACTION = ( app.URI.length > 1 && app.URI[1] !== 'false' ) ? 'edit' : 'add';
        }

        // HIDE MSG
        if( app.VIEW[app.HASH].DOM.hasOwnProperty('msg') ) {
            app.VIEW[app.HASH].DOM.msg.addClass('hide');
        }

        // SAVE: FORCE OFFLINE
        // if form already in ajax process or forced offline save
        if( action === '' || app.FORM.AJAX || options.offline || app.check_connection() === false ) {

            if( typeof(app.VIEW[app.HASH].form_offline) === 'function') {

                console.log('Manually save offline...');

                app.VIEW[app.HASH].form_offline(false, data, data.tbl);

            }

            return;
        }

        // change ajax status
        app.FORM.AJAX = true;
        app.DOM.header_sync.show();

        // POST FORM
        var ajax = $.ajax({
            url: app.get_api_url(action),
            type: 'POST',
            timeout: ( options.timeout ) ? options.timeout : app.TIMEOUT_AJAX,
            data: data
        });

        // SUCCESS
        ajax.done(function(response, exception, XHR){

            // ACOUNT
            if( response.account ) {
                app.check_user_account(response.account);
            }

            if( response.status === 'error' ) {

                // API: OK
                // DATA: ERROR
                app.FORM.success_error(response);

            } else if( response.status === 'success') {

                // API: OK
                // DATA: OK
                app.FORM.success_valid(response, data);

            } else {

                // API: ERROR
                // DATA: OK
                app.FORM.fail(XHR, exception, 'There appears to be a problem with the system please try again later.');

                // callback error
                if( typeof(app.VIEW[app.HASH].form_offline) === 'function') {
                    app.VIEW[app.HASH].form_offline(response,data);
                }
            }
        });

        // ERROR
        ajax.fail(function(response, exception, msg) {

            // API: N/A
            // DATA: N/A
            // CONNECTION: ERROR
            
            // call function of view if exists
            if( typeof(app.VIEW[app.HASH].form_offline) === 'function') {
                app.VIEW[app.HASH].form_offline(response,data);
            }

            // standard form exception
            if( app.HASH === 'login' ) {
                app.FORM.fail(response, exception, msg);
            }
        });

        // CLEAN
        ajax.always(function(){
            app.FORM.AJAX = false;
            app.DOM.header_sync.hide();
        });
    },

    /**
     * Generic no interenet connection error for form submission
     */
    fail: function(XHR, exception, msg)
    {
        console.log('----------');
        console.log('ERROR', XHR, exception, msg);
        console.log('----------');

        if( XHR.status === 0 || msg === undefined ) {
            msg = "Sorry, we couldn\'t find an internet connection. Please check you\'re connected and try again.";
        } else {
            msg += ' ('+XHR.status+')';
        }

        var html =
            '<span class="msg-error">' +
            '<i class="fa fa-exclamation-circle"></i>' + msg +
            '</span>';

        // update message
        app.FORM.msg(html, 'error');

        // READY the form
        app.FORM.loading();
    },

    /**
     * Hide/show form
     */
    loading: function(action)
    {
        var fn_loading = ( action === undefined ) ? 'toggle' : action,
            fn_btns = 'toggle';

        // specific hide/show
        if( fn_loading !== 'toggle' ) {
            if( fn_loading === 'hide' ) {
                fn_btns = '';
            } else {
                fn_btns = 'loading';
            }
        }

        // dont toggle loading
        // no loading areas
        if( app.DOM.footer.is(':visible') === false && app.VIEW[app.HASH].DOM.hasOwnProperty('bottom_container') === false ) {
            return;
        }

        var dom = ( app.DOM.footer.is(':visible') ) ? app.DOM.footer : app.VIEW[app.HASH].DOM.bottom_container;

        // TOGGLE LOADING
        if( fn_btns === 'toggle' ) {
            dom.toggleClass('loading');
        } else {
            dom.removeClass('loading').addClass(fn_btns);
        }
    },

    /**
     * Generic no interenet connection error for form submission
     */
    success_error: function(ajax)
    {
        console.warn('ERROR: app.FORM.success_error()');

        var msg = '';

        for (let i = 0; i < ajax.errors.length; i++) {
            msg +=
                '<span data-id="'+ajax.errors[i].key+'" class="msg-error">' +
                '<i class="fa fa-exclamation-circle"></i>' +
                ajax.errors[i].msg +
                '</span>';

            // add error to input
            app.VIEW[app.HASH].DOM.form.find('#' + ajax.errors[i].key).addClass('error').parent().addClass('error');
        }

        // UPDATE MESSAGE
        app.FORM.msg(msg, 'error');

        // HIDE BUTTONS/LOADING
        app.FORM.loading('hide');

        // check if error function
        if( typeof(app.VIEW[app.HASH].form_online_error) === 'function' ) {
            app.VIEW[app.HASH].form_online_error(ajax.errors);
        }
    },

    /**
     * Update Form confirmation msg
     * toggle form
     * call view callback
     */
    success_valid: function(ajax, data, dontSave)
    {
        var msg = '<span class="msg-success"><i class="fa fa-check-circle" aria-hidden="true"></i>' +ajax.msg +'</span>';

        // UPDATE MESSAGE
        app.FORM.msg(msg, 'success');

        // HIDE LOADING
        app.FORM.loading('hide');

        // CLEAR SUCCES MSGS
        this.success_msg_clear();

        if( dontSave === true) {
            return;
        }

        // FORM SUCCESS DATA IS CLEAN AS WHISTLE
        if( typeof(app.VIEW[app.HASH].form_online) === 'function') {
            (async () => await app.VIEW[app.HASH].form_online(ajax, data))();
        } else {
            console.log('No form_online');
        }
    },

    /**
     * Show message confirmation and fade out
     * @param [string] msg
     */
    msg: function(msg, status)
    {
        // show msg
        if(app.VIEW[app.HASH] && app.VIEW[app.HASH].DOM && app.VIEW[app.HASH].DOM.msg) {
            app.VIEW[app.HASH].DOM.msg.html(msg).removeClass('hide').show();
        }
    },

    /**
     * Prepare item to save offline
     */
    save_offline: function(tbl, action, data, redirectOff, idTbl, uploadFile, callback, hideConfirmation)
    {
        console.log('app.FORM.save_offline()');

        if(action === 'delete' && data === false) {
            data = {};
        }

        // clean data
        data = this.clean_json(data);

        // ADD ONLY
        if( action === 'add' ) {

            // offline data
            data.ts = app.DATE.timestamp(false, true);
            data.date_added = app.DATE.format('datetime');

        } else if( action === 'delete' ) {

            // remove unwanted items
            if( data.ts !== undefined && data.ts === '' ) {
                delete data.ts;
            }

            if( data.id !== undefined && data.id === '' ) {
                delete data.id;
            }

            // mark action as edit so we dont need to delete row completely
            // only if id exist and not create offline with .ts
            if( data.id ) {
                action = 'edit';

                // mark row as deleted
                data.is_deleted = '1';
            }
        }

        // date updated
        if( action === 'edit') {
            data.date_updated = app.DATE.format('datetime');
        }

        // mark as not synced
        data.sync = false;

        // action normal save
        this.save(tbl, action, data, data, redirectOff, idTbl, true, false, uploadFile, false, callback);

        // return value if needed
        if( redirectOff && !hideConfirmation ) {

            app.FORM.success_valid({ 'msg': 'Succesfully saved ' + app.TBL[tbl].i + ' (offline).'}, false, true);

            return data.ts;
        }
    },

    /**
     * Clear succesfully msgs after 1 second
     *
     * Can be cleared by scroll or click on document
     */
    success_msg_clear: function()
    {
        var hash = app.HASH;

        setTimeout(function(){
            // click anywhere on doc to hide msgs
            $(document).on('click scroll', function(e){

                // hide msgs
                if( app.VIEW[app.HASH] && hash === app.HASH && app.VIEW[app.HASH].DOM.hasOwnProperty('msg') ) {
                    app.VIEW[app.HASH].DOM.msg.fadeOut();
                }

                // unbind future document clicks not needed
                $(document).unbind('click scroll');
            });
        }, 1000 );
    },

    get_save_index: function(tbl, action, json, post, idTbl)
    {
        var index = false;

        // EDIT/DELETE
        if( action === 'edit' || action === 'delete' ) {


            // use timestamp first as it may not have an id yet
            var identifier = false;

            if( post && post.ts ) {
                identifier = post.ts;
            } else if( $.isNumeric(json.id) ) {
                identifier = json.id;
            } else if( $.isNumeric(idTbl) || (idTbl && idTbl.split('_').length === 2 ) ) {
                identifier = idTbl;
            }

            index = app.cache_get_index(tbl, identifier);
        }
        
        return index;
    },

    redirect: function(tbl, redirectOff, idTbl)
    {
        // REDIRECT
        if( typeof redirectOff === 'undefined' || redirectOff === false ) {

            // check page exists
            if( !app.ROUTES[tbl] ) {
                
                if( app.ROUTES[tbl+'_form'] ) {
                    // check form page exists
                    tbl += '_form';
                } else if( app.ROUTES[tbl+'_list'] ) {
                    // check list page exists
                    tbl += '_list';
                }
            }

            app.redirect(tbl+'/'+idTbl+'/success');

        } else if( typeof redirectOff === 'string' ){
            app.redirect(redirectOff+idTbl);
        }
    },

    /**
     * Remove unwanted data from json object
     */
    clean_json: function(json)
    {
        // properties to delet
        var clean = ['action', 'app', 'app_version', 'encryption_key', 'tbl', 'usrId', 'usrEmail', 'usrPin', 'usr'];

        // remove unwanted if present
        if( json && json.ts && json.ts === '' ) {
            delete json.ts;
        }

        // loop through items to delete
        $.each(clean, function(k,v){
            delete json[ v ];
        });

        return json;
    },

    /**
     * Clean row for file
     */
    clean_file: function(sync, syncFile, item, json)
    {
        var fields = app.TPL._get_photo_fields(8);

        // LOOP through potential file fields
        $.each(fields, function(k,v){

            // check for online file delete if local file present
            if( syncFile ) {
                 
                if( item[v] && item[v+'_local'] ) {
                    delete item[v];
                }
            }

            // sync file
            if( syncFile && json.field === v ) {
                delete item[v + '_needs_uploading'];
            }

            // remove need to upload file if different
            if( sync && json.id && ( syncFile === undefined || syncFile === false ) && json[v] && item[v] && json[v] !== item[v] ) {
                delete item[k + '_needs_uploading'];
            }
        });

        return item;
    },

    /**
     * Save the information to the CACHE
     *
     * 1) called from ajax
     * 2) called from save_offline()
     */
    save: function(tbl, action, json, post, redirectOff, idTbl, offline, sync, uploadFile, syncFile, callback)
    {
        var index = this.get_save_index(tbl, action, json, post, idTbl);
         
        // EDIT + DELETE
        // GET THE ID
        if( idTbl === false || idTbl === undefined && action === 'edit' || action === 'delete' ) {

            // get id of tbl to delete from
            if( json.id ) {
                idTbl = json.id;
            } else if( json.ts ) {
                idTbl = json.ts;
            } else if( post.ts ) {
                idTbl = post.ts;
            } else {
                idTbl = json;
            }
        }

        // CLEAN JSON BEFORE SAVING
        json = this.clean_json(json);

        if( action === 'add' ) {

            // get id of newly saved item
            idTbl = this.save_add(tbl, json, index, offline, sync);

        } else if( action === 'edit' ) {
             

            // get id of edited item
            // could have changed from timestamp to id
            idTbl = this.save_edit(tbl, json, index, offline, sync, syncFile);
        
        } else if( action === 'delete' ) {

            if( app.TBL[tbl] && app.TBL[tbl].keep_deleted ) {

                idTbl = this.save_edit(tbl, {is_deleted: '1'}, index, offline, sync);

            } else {

                this.save_delete(tbl, json, index);
            }
        }

        // SAVE
        if( sync === undefined || sync === false || sync === 'force' ) {
            app.cache_save(tbl, false, false, callback);
        }

        // UPLOAD FILE
        if( uploadFile === true ) {
            app.FILE.upload_pic(tbl, action, json)
                .catch((err) => console.warn(`Something went wrong uploading File: ${err.message}`));
            return;
        }

        // FORCE REDIRECT NEEDED IF NEW ID FOUND so we arent using ts in URI
        if( action === 'edit' && sync !== true && !offline && post.ts && redirectOff !== true ) {
            redirectOff = false;
        }

        // CHECK REDIRECT
        this.redirect(tbl, redirectOff, idTbl);
    },

    /**
     * Return item id
     */
    save_add: function(tbl, data, index, offline, sync)
    {
        var identifier = data.id || data.ts;

        // add record to correct object
        app.CACHE[tbl.toUpperCase()].push(data);
        
        return identifier;
    },

    save_edit: function(tbl, json, index, offline, sync, syncFile)
    {
        if(!tbl) {
            return false;
        }

        // get item
        var item = app.CACHE[tbl.toUpperCase()][index];

        // couldnt find item
        if( !item ) {
            console.error('Couldnt find ' + tbl + ' record', json, index);
            return false;
        } else if( item.usrPin ) {
            item = this.clean_json(item);
        }

        // force record to use new id
        if( item.ts && json.id ) {

            // update related items with new FK id
            if( app.TBL[tbl].hasOwnProperty('tblChild') ) {
                this.save_edit_child(tbl, app.TBL[tbl].tblChild, item.ts, json.id, sync);
            }

            // update related report row to use new ID
            if( tbl === 'rep' && item.hasOwnProperty('report_id_related') ) {
                this.save_edit_rep_related(item, json);
            }

            delete item.ts;
            delete item.sync;
            delete json.ts;
        }

        // remove need for sync if id present in json
        if( sync && json.id ) {
            delete item.sync;
            delete json.is_conflict;
        }
         
        // check for online file delete if local file present
        if( sync || syncFile ) {
            item = this.clean_file(sync, syncFile, item, json);

            if( syncFile && json.field ) {
                delete json.field;
            }
        }

        // merge item & data
        item = $.extend(item, json);

        if( item.id ) {
            console.log(tbl, '#id', item.id);
        } else {
            console.log(tbl, '#ts', item.ts);
        }

        return item.id || item.ts;
    },

    save_edit_rep_related: function(item, json)
    {
        var id = false;

        $.each(app.CACHE.REP, function(k,v){
            if( v.id === item.report_id_related || v.ts === item.report_id_related ){
                id = v.id;
                app.CACHE.REP[k].report_id_related = json.id;
                return false;
            }
        });

        console.log('FIND report ', id);

        if( id ) {
            app.cache_save('rep');
            console.log('SET report related as ', json.id);
        }
    },

    save_edit_child: function(tbl, tblChild, idOld, idNew)
    {
        var field = app.TBL[tblChild].tblParentProp;

        // missing foreign key
        if( !field ) {
            console.error('Could not find .tblParentProp field for '+tblChild);
        }

        // loop through children to update
        $.each(app.CACHE[tblChild.toUpperCase()], function(k,v){

            if( v[field] === idOld ) {

                // update foreign key
                v[field] = idNew;

                app.CACHE[tblChild.toUpperCase()][k] = v;
            }
        });

        app.cache_save(tblChild);
    },

    save_delete: function(tbl, data, index)
    {
        app.CACHE[tbl.toUpperCase()].splice(index, 1);
    }
};

/**
 * Additional JQuery validators
 */

/**
 * JQuery Validate custom validators
 */
$.validator.addMethod("require_from_group",function(a,e,t){var d=$(t[1],e.form),i=d.eq(0),r=i.data("valid_req_grp")?i.data("valid_req_grp"):$.extend({},this),l=d.filter(function(){return r.elementValue(this)}).length>=t[0];return i.data("valid_req_grp",r),$(e).data("being_validated")||(d.data("being_validated",!0),d.each(function(){r.element(this)}),d.data("being_validated",!1)),l},$.validator.format("Please fill at least {0} of these fields."));
// jquery validation custom rules
$.validator.addMethod('date',function(e,t){var a=!1,r=/^\d{1,2}\-\d{1,2}\-\d{4}$/;if(r.test(e)){var n=e.split("-"),d=parseInt(n[0],10),l=parseInt(n[1],10),s=parseInt(n[2],10),i=new Date(s,l-1,d);a=i.getFullYear()===s&&i.getMonth()===l-1&&i.getDate()===d?!0:!1}else a=!1;return this.optional(t)||a},"Please enter a valid Date");
$.validator.addMethod('notEqualTo', function(v,e,p) { return this.optional(e) || v !== p;}, 'Value cannot be equal to this.');
