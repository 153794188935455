import * as Handlebars from 'handlebars';
import {Capacitor} from "@capacitor/core";
import {FILE} from "./core/file";

Handlebars.registerHelper('fuelExpensesMileageHours', function(result){

    if( result.hasOwnProperty('mileage') === false && result.hasOwnProperty('hours') === false ) {
        return '';
    }

    if( result.hasOwnProperty('hours') ) {
        // hours
        return `<p class="ac">
            <label for="hours">Hours of operation</label>
            <span class="tip">Please enter the hours of operation reading:</span>
            <input type="number" inputmode="numeric" class="required number" id="hours" name="hours" value="${result.hours}">
        </p>`;
    }

    // mileage
    return `<p class="ac">
        <label for="mileage">Odometer reading</label>
        <span class="tip">Please enter the odometer reading:</span>
        <input type="number" inputmode="numeric" class="required number" id="mileage" name="mileage" value="${result.mileage}">
    </p>`;
});

Handlebars.registerHelper('fuelExpensesPriv', function(opts){
    return ( app.CACHE.USR.opr_priv_fuel_expenses === '1' && app.CACHE.USR.priv_fuel_expenses === '1' ) ? opts.fn(this) : opts.inverse(this);
});

/**
 * Set opacity to zero if file doesnt load
 */
Handlebars.registerHelper('getVehiclePicture', function(r, thumb){

    var src = Handlebars.helpers.getFileSlug('veh', r, 'file', thumb),
        e = ' onerror="this.style.opacity=\'0\'" ',
        css = ( thumb === true ) ? 'thumb' : '';

    if( !r.file && !r.slug_file_thumb ) {
        return '';
    }

    console.log(r.file);

    if( r.file ){
        return `<img id="profile" class="${css}" src="${src}" '${e}'>`;
    }
    
    return `<img id="profile" class="${css}" src="${r.slug_file_thumb}" ${e}>`;
});

// does inspection question allow gallery 
Handlebars.registerHelper('ifPhotoFromGalleryAllowed', function(question, opts){

    if( !question.is_photo_from_gallery || question.is_photo_from_gallery === '1' ) {
        return opts.fn(this);
    }

    return opts.inverse(this);
});

/** {{#ifCond 5 '>' 1 }}
 *   // do stuff
 * {{/ifCond}}
 */
Handlebars.registerHelper('ifCond', function(a, operator, b, opts) {

    switch (operator) {
        case '==':
            return (a == b) ? opts.fn(this) : opts.inverse(this);
        case '===':
            return (a === b) ? opts.fn(this) : opts.inverse(this);
        case '!==':
            return (a !== b) ? opts.fn(this) : opts.inverse(this);
        case '<':
            return (parseInt(a, 10) < parseInt(b, 10)) ? opts.fn(this) : opts.inverse(this);
        case '<=':
            return (parseInt(a, 10) <= parseInt(b, 10)) ? opts.fn(this) : opts.inverse(this);
        case '>':
            return (parseInt(a, 10) > parseInt(b, 10)) ? opts.fn(this) : opts.inverse(this);
        case '>=':
            return (parseInt(a, 10) >= parseInt(b, 10)) ? opts.fn(this) : opts.inverse(this);
         case '||':
            return (a || b) ? opts.fn(this) : opts.inverse(this);
         case '&&':
            return (a && b) ? opts.fn(this) : opts.inverse(this);
         case '&!':
            return (a && !b) ? opts.fn(this) : opts.inverse(this);
        default:
            return opts.inverse(this);
    }
});

Handlebars.registerHelper('que_typ_tyre', function(que_typ_id, opts){

    if( que_typ_id === app.CACHE.MAI_QUE_TYP.tyre || que_typ_id === app.CACHE.MAI_QUE_TYP.tyre_age ) {
        return opts.fn(this);
    }

    return opts.inverse(this);
});

// {{#if VEH.reg}}
//     <p class="ac id_reg id_reg_{{VEH.reg.length}}">{{regFormat VEH.reg}}</p>
// {{else}}
//     <p class="ac id_serial id_serial_{{VEH.serial.length}}">{{VEH.serial}}</p>
// {{/if}}
Handlebars.registerHelper('regSerial', function(r, small, slug, img) {

    // return empty string if no object passed/found
    if( !r ) {
        return '-';
    }

    // asset identifier
    var id = '',
    // value of reg/serial
        val = '',
        countryBlue = '',
        // default html tag
        tag = 'p',
        // url
        href = '';

    if( r.reg || r.serial ) {
        id = ( r.reg ) ? 'reg' : 'serial';
        val = ( id === 'reg' ) ? Handlebars.helpers.regFormat(r.reg) : r.serial;
    } else {

        if( r.id_type ){
            id = r.id_type.replace('id_','');     
        } else if( r.id_name_type ){
            id = r.id_name_type.replace('id_',''); 
        }

        val = ( id === 'reg' ) ? Handlebars.helpers.regFormat(r.id_name) : r.id_name;
    }

    // similar object properties
    var id_small = ( small === true ) ? 'id_'+id+'_small' : '',
        country = ( r.reg_country ) ? 'id_'+id+'_'+r.reg_country : '';

    // IRL PLATE
    if( r.reg_country === 'irl' ) {
        countryBlue = '<span class="country">'+
            '<img src="img/eurostars.png">'+
            '<span class="country-code">'+
                r.reg_country+
            '</span>'+
        '</span>';
    }

    if( typeof(slug) === 'string' ) {

        // href
        if( slug === 'span' ) {
            tag = slug;
        } else {
            href = 'href="#'+slug+'" ';
            tag = 'a';
        }
    }

    var val_length = ( val ) ? val.length : 0;

    if( img === true && r.file ){
        img = Handlebars.helpers.getVehiclePicture(r, true);
    } else {
        img = '';
    }

    // list slug?
    return `<${tag} data="${r.id}" ${href} class="ac id_${id} id_${id}_${val_length} ${country} ${id_small}">${countryBlue}${val}${img}</${tag}>`;
});

/**
 * Check if inspection answer requires a notes
 *
 * By either:
 *
 * forcing is_required_notes = 1
 * OR
 * question type is text_only
 */
Handlebars.registerHelper('ifMaiNotesRequired', function(que, opts) {
    return ( que && ( que.is_required_notes === '1' || que.type === app.CACHE.MAI_QUE_TYP.text_only ) ) ? opts.fn(this) : opts.inverse(this);
});

/**
 * Upcoming vehicle dates and service info
 */
Handlebars.registerHelper('vehUpcoming', (veh) => {

    if(!veh.id && !app.CACHE.VEH_UPCOMING[veh.id]) {
        return '';
    }

    return `<a id="btn-upcoming" class="button button-blue" data="${veh.id}">
        <i class="fa fa-calendar-alt"></i>
        Dates &amp; Services
    </a>`;
});

/**
 * Check if inspection answer requires a photo
 */
Handlebars.registerHelper('ifMaiAnsHasPhoto', function(a, opts) {

    var photo = false;

    // loop through each 
    $.each(a.pho, function(k,v){
        if( v.file ) {
            // mark as true
            photo = true;
            // dont loop further
            return false;
        }
    });

    return ( photo ) ? opts.fn(this) : opts.inverse(this);
});

Handlebars.registerHelper('multiAnswer', function(val, index, opts) {

    if( !val ) {
        return '';
    }

    // split answer into chunks
    var ans = val.split(',');

    return ans[index];
});

Handlebars.registerHelper('isPositive', function(val, opts) {

    if( val && $.isNumeric(val) && val > parseInt(0) ) {
        return opts.fn(this);
    }

    return opts.inverse(this);

});

Handlebars.registerHelper('ifSimilarDefect', function(action, value, options) {

    if(
        (action === 'yes' && value && value !== '0') ||
        (action === 'no'  && (!value || value === '0') ) 
    ) {
        return options.fn(this);
    }

    return options.inverse(this);
});

Handlebars.registerHelper('safetyNotResolved', function(mai_ans, options) {

    var safety = false;

    $.each(mai_ans, function(k,v){

        if( v.health && v.health === app.CACHE.MAI_ANS_HEALTH.safety.id && ( v.is_resolved === '0' || !v.is_resolved ) ) {
            safety = true;
        }
    });

    if( safety ) {
        return options.fn(this);
    }

    return options.inverse(this);
});

Handlebars.registerHelper('maiEditable', function(progress, options) {

    if( progress.incomplete || progress.status === 'Ready to Complete' ) {
        return options.fn(this);
    }

    return options.inverse(this);
});

Handlebars.registerHelper('tyre', function(type, r, mai) {

    // emtpy axle
    var axle = {
        lo: { 'd': '', 'p': ''},
        li: { 'd': '', 'p': ''},
        ri: { 'd': '', 'p': ''},
        ro: { 'd': '', 'p': ''}
    };

    var answers = ( r.answer ) ? JSON.parse(r.answer) : [],
        // vehicle from inspection
        veh = app.TPL.get_row('veh', mai.vehicle_id),
        // no. of axles if set
        vehAxles = app.VEHICLES.getAxleCount(veh),
        showRowCount = 0;

    // html
    var html = ''+
    '<div class="axle-container axle-container-tyre">'+
        '<table data="'+vehAxles+'" class="axle">'+
            '<tbody>';

    for(let i = 0; i < app.MAX_AXLES; i++) {

        var no = i + 1;

        // display row or not?
        var showRow = ( i <= 1 || (!r.answer && vehAxles && i < vehAxles) || answers[i] ) ? 'show' : '';

        // add one count
        if( showRow ) {
            showRowCount++;
        }

        var a = ( answers[i] ) ? answers[i] : axle;

        // workout which tyres are visible
        var tyres = {
            lo: ( a.lo.d || a.lo.p ) ? '' : ' disabled',
            li: ( a.li.d || a.li.p ) ? '' : ' disabled',
            ri: ( a.ri.d || a.ri.p ) ? '' : ' disabled',
            ro: ( a.ro.d || a.ro.p ) ? '' : ' disabled'
        };

        var disabled = ( mai.hasOwnProperty('id') ) ? ' disabled ' : '';

        // CHANGE LABEL BASED ON TYPE
        var dLabel =   ( type === 'tyre_age' ) ? '4 Digit Code' : 'Depth<br>(mm)',
            dAttribs = ( type === 'tyre_age' ) ? ' maxlength="4" ' : '';

        html += '<tr class="'+showRow+'">'+
            '<td class="lo">'+
                '<label>'+dLabel+'</label>'+
                '<input type="number" class="tyre tyre-lo" data-type="'+type+'" data-field="answer" name="lo[d]" value="'+a.lo.d+'" '+disabled+dAttribs+'>';

            // tyre only
            if( type === 'tyre' ) {
                html += '<br>'+
                '<input type="number" class="tyre tyre-lo" data-type="'+type+'" data-field="answer" name="lo[p]" value="'+a.lo.p+'" '+disabled+'>'+
                '<label>Pressure<br>(psi)</label>';
            }

            html += '</td>'+

            '<td class="to l"><img src="img/tyre.png" class="tyre-lo '+tyres.lo+'"></td>'+
            '<td class="ti l"><img src="img/tyre.png" class="tyre-li '+tyres.li+'"></td>'+

            '<td class="li">'+
                '<label>'+dLabel+'</label>'+
                '<input type="number" class="tyre tyre-li" data-type="'+type+'" data-field="answer" name="li[d]" value="'+a.li.d+'" '+disabled+dAttribs+'>';

            // tyre only
            if( type === 'tyre' ) {
                html += '<br>'+
                '<input type="number" class="tyre tyre-li" data-type="'+type+'" data-field="answer" name="li[p]" value="'+a.li.p+'" '+disabled+'>'+
                '<label>Pressure<br>(psi)</label>';
            }
            html += '</td>'+
            '<td class="mid r">'+
                '<span class=label>Axle</span>'+
                '<span class="axle"></span>'+
                '<span class=label>'+no+'</span>'+
            '</td>'+

            '<td class="ri">'+
                '<label>'+dLabel+'</label>'+
                '<input type="number" class="tyre tyre-ri" data-type="'+type+'" data-field="answer" name="ri[d]" value="'+a.ri.d+'" '+disabled+dAttribs+'>';

            // tyre only
            if( type === 'tyre' ) {
                html += '<br>'+
                '<input type="number" class="tyre tyre-ri" data-type="'+type+'" data-field="answer" name="ri[p]" value="'+a.ri.p+'" '+disabled+'>'+
                '<label>Pressure<br>(psi)</label>';
            }

            html += '</td>'+
            '<td class="to r"><img src="img/tyre.png" class="tyre-ri '+tyres.ri+'"></td>'+
            '<td class="ti r"><img src="img/tyre.png" class="tyre-ro '+tyres.ro+'"></td>'+

            '<td class="ro">'+
                '<label>'+dLabel+'</label>'+
                '<input type="number" class="tyre tyre-ro" data-type="'+type+'" data-field="answer" name="ro[d]" value="'+a.ro.d+'" '+disabled+dAttribs+'>';

            // tyre only
            if( type === 'tyre' ) {
                html += '<br>'+
                '<input type="number" class="tyre tyre-ro" data-type="'+type+'" data-field="answer" name="ro[p]" value="'+a.ro.p+'" '+disabled+'>'+
                '<label>Pressure<br>(psi)</label>';
            }

            html += '</td>'+
        '</tr>';
    }

    // button initial states
    var button_add = ( showRowCount === app.MAX_AXLES ) ? 'grey' : 'green',
        button_delete =( showRowCount === app.MIN_AXLES ) ? 'grey' : 'red';

    // tyre add
    if( mai.hasOwnProperty('id') === false ) {
        html += '<tr class="controls">'+
        '<td colspan="9">'+
            '<a class="button button-35 button-axle button-axle-add button-'+button_add+'">Add Axle</a>'+
           '<a class="button button-35 button-axle button-axle-delete button-'+button_delete+'">Delete Last Axle</a>'+
        '</td>'+
        '</tr>';
    }

    return html + '</tbody></table></div>';
});

Handlebars.registerHelper('brake_radio', function(label, field, data) {

    var choices = app.MAINTENANCE.getBrakeQuestionChoices(field);

    // add test questions
    let html = '<p class="radio-container extra-'+field+'-container">'+
           '<span class="b">'+label+'</span>';

        $.each(choices, function(k,v){

            var selected = ( data[field] === v.id ) ? 'selected' : '';

            html += '<a class="button button-radio '+selected+'" data="'+v.id+'" data-field="extra.'+field+'">'+v.name+'</a>';
        });

    html += '</p>';

    return html;
});

Handlebars.registerHelper('datepicker', function(ans, mai) {

    var disabled = ( mai.id || mai.date_end ) ? ' disabled ' : '';

    // full width - padding
    var value = (ans.answer) ? ` value="${ans.answer}" ` : '';

    // unique identifier
    var id = 'datepicker_'+Math.random().toString(36).slice(2, 7);

    return `<div class="datepicker-answer-container">
        <p>
            <label for="${id}">Please enter date below <i class="fad fa-calendar"></i></label>
            <input type="date" id="${id}" data-type="date" class="date" data-field="answer" ${value} ${disabled}>
        </p>
    </div>`;
});

Handlebars.registerHelper('signature', function(ans, mai) {

    if( mai.id || mai.date_end ) {
        return `<p class="signature-online">The signature can now only be viewed in the CMS</p>`;
    }

    // full width - padding
    var width = app.DOM.content_load.width() - 20,
        value = (ans.answer) ? ` value="${ans.answer}" ` : '';

    return `<div class="signature-container">
        <p>
        <label>Please sign below <i class="fad fa-pen"></i></label>
        <input type="hidden" data-type="signature" class="signature" data-field="answer" ${value}>
        <canvas width="${width}" height="175"></canvas>
        <a class="btn-save button button-grey">Save</a>
        <a class="btn-edit button" style="display:none">Edit</a>
        <a class="btn-clear button button-grey">Clear</a>
        </p>
    </div>`;
});

Handlebars.registerHelper('brake', function(type, r, mai, que) {

    // emtpy axle
    // f = force
    // t = temp
    // l = locked
    // 
    // brake_wear
    // d = disc
    // p = pad
    var axle = {
        lo: { 'f': '', 't': '', 'l': '', 'd': '', 'p': ''},
        ro: { 'f': '', 't': '', 'l': '', 'd': '', 'p': ''}
    };

    var answers = ( r.answer ) ? JSON.parse(r.answer) : [],
        // vehicle data only for pre-answers
        veh = app.TPL.get_row('veh', mai.vehicle_id),
        vehAxles = app.VEHICLES.getAxleCount(veh);

    // extra settings (not all brake questions have extra data)
    var extra = {}; 

    if( r.extra ) {
        extra = JSON.parse(r.extra);
    } else if( type === 'brake' ) {
        extra = {'lock': '0'};
    }

    // used for checkbox ids (unique identifier)
    var ts = ( r.ts ) ? r.ts+'-' : '';

    var axleTest = ( extra.test ) ? ' axle-container-brake-'+extra.test : '';

    var html = '<div class="axle-container axle-container-brake '+axleTest+'">',
        showRowCount = 0;

    // dvsa link
    if( type === 'brake' ) {

        var decelerometer = ( extra.decelerometer ) ? extra.decelerometer : '';

        html += '<a class="button browser-open button-dvsa" href="https://www.gov.uk/government/publications/the-heavy-vehicle-brake-test-best-practice-guide/the-heavy-vehicle-brake-test-best-practice-guide"><i class="fa fa-link"></i> DVSA Brake Test Guide</a>'+
        // radio: test
        Handlebars.helpers.brake_radio('How has this test been done?', 'test', extra)+
        Handlebars.helpers.brake_radio('Was the asset laden?', 'laden', extra)+
        Handlebars.helpers.brake_radio('Did the brakes lock?', 'lock', extra) +
        `<div class="extra-decelerometer-container number-container">
            <p>
            <label>Speed (km/h)</label>
            <input type="number" class="number decelerometer" data-field="extra.decelerometer" value="${decelerometer}" min="0" max="100">
            </p>
        </div>`
        '<div class="seperator"></div>';
    }

    // add health questions
    if( type === 'brake' && que && que.is_health === '1' ) {
        html += Handlebars.helpers.radio_health(r, 'brake');
    }

    html += '<table data="'+vehAxles+'" class="axle axle-brake"><tbody>';

    // question locked
    var qBrake = function(ref, f, disabled, a, no){
        var checked = ( a[ref][f] ) ? ' checked="checked" ' : '',
            id = ts+'axel-locked-'+ref+'-'+f+'-'+no;

        // locked
        var h = '<label class="checkbox-label">Locked?</label>'+
        '<input type="checkbox" id="'+id+'" class="checkbox text tyre brake tyre-'+ref+'" data-field="answer" name="'+ref+'['+f+']" value="1" '+checked+' '+disabled+'>'+
        '<label class="checkbox-container" for="'+id+'"><i class="fa fa-check green"></i></label>'+
        '<div class=c></div>';

        // axel-temp
        h += '<span class="axle-temp">'+
            '<input type="text" class="number uc tyre brake brake-temp tyre-'+ref+'" data-field="answer" name="'+ref+'[t]" value="'+app.val(a[ref].t)+'" '+disabled+'>'+
            '<label>Temp. (&deg;C)</label>'+
        '</span>';
                
        // axel-kgf
        h += '<span class="axle-kgf">'+
            '<input type="text" class="number uc tyre brake brake-kgf tyre-'+ref+'" data-field="answer" name="'+ref+'[f]" value="'+app.val(a[ref].f)+'" '+disabled+'>'+
            '<label>Force (kgf)</label>'+
        '</span>';

        return h;
    };

    // question locked
    var qBrakeWear = function(type, ref, disabled, a){

        // disc wear
        var h = '<span class="axle-disc-wear">'+
            '<input type="number" class="number uc tyre brake disc-wear tyre-'+ref+'" data-field="answer" name="'+ref+'[d]" value="'+app.val(a[ref].d)+'" '+disabled+' min="0" max="100">'+
            '<label>Disc Wear<br>(%)</label>'+
        '</span>';

        // overwrite default disc wear with torque value
        if( type === 'brake_wear_torque_pad' ){
            h = '<span class="axle-torque">'+
            '<input type="number" class="number uc tyre brake torque tyre-'+ref+'" data-field="answer" name="'+ref+'[t]" value="'+app.val(a[ref].t)+'" '+disabled+' min="0" max="999">'+
            '<label>Torque<br>(Nm)</label>'+
        '</span>';
        }
                
        // pad wear
        h += '<span class="axle-pad-wear">'+
            '<input type="number" class="number uc tyre brake pad-wear tyre-'+ref+'" data-field="answer" name="'+ref+'[p]" value="'+app.val(a[ref].p)+'" '+disabled+' min="0" max="100">'+
            '<label>Pad Wear<br>(%)</label>'+
        '</span>';

        return h;
    };

    // loop through possible axles
    for(let i = 0; i < app.MAX_AXLES; i++) {

        var no = i + 1;

        // display row or not?
        var showRow = ( i <= 1 || (!r.answer && vehAxles && i < vehAxles) || answers[i] ) ? 'show' : '';

        // add one count
        if( showRow ) {
            showRowCount++;
        }

        var a = ( answers[i] ) ? answers[i] : axle;

        // workout which tyres are visible
        var tyres = {
            lo: ( ((extra.test === '1' || extra.test === '2') && a.lo.l) || ( extra.test === '2' && a.lo.f) || ( extra.test === '1' && a.lo.t ) ) ? '' : ' disabled',
            ro: ( ((extra.test === '1' || extra.test === '2') && a.ro.l) || ( extra.test === '2' && a.ro.f ) || ( extra.test === '1' && a.ro.t ) ) ? '' : ' disabled'
        };

        var disabled = ( mai.hasOwnProperty('id') ) ? ' disabled ' : '';

        html += '<tr class="'+showRow+'">'+
            '<td class="lo">';

                // brake
                if( type === 'brake' ) {
                    html += qBrake('lo', 'l', disabled, a, no);
                } else if( type === 'brake_wear' || type === 'brake_wear_torque_pad' ) {
                    html += qBrakeWear(type, 'lo', disabled, a);
                }
                
        html += '</td>'+
            '<td class="to"><img src="img/brake-left.png" class="number uc tyre-lo '+tyres.lo+'"></td>'+
            '<td class="mid r">'+
                '<span class="label">Axle</span>'+
                '<span class="axle"></span>'+
                '<span class="label">'+no+'</span>'+
            '</td>'+
            '<td class="ti"><img src="img/brake-right.png" class="number uc tyre-ro '+tyres.ro+'"></td>'+
            '<td class="ro">';

            // locked
            if( type === 'brake' ) {
                html += qBrake('ro', 'l', disabled, a, no);
            } else if( type === 'brake_wear' || type === 'brake_wear_torque_pad' ) {
                html += qBrakeWear(type, 'ro', disabled, a);
            }
        html += '</td>'+
        '</tr>';
    }

    // button initial states
    var button_add = ( showRowCount === app.MAX_AXLES ) ? 'grey' : 'green',
        button_delete =( showRowCount === app.MIN_AXLES ) ? 'grey' : 'red';

    // tyre add
    if( mai.hasOwnProperty('id') === false ) {
        html += '<tr class="controls">'+
        '<td colspan="9">'+
            '<a class="button button-35 button-axle button-axle-add button-'+button_add+'">Add Axle</a>'+
           '<a class="button button-35 button-axle button-axle-delete button-'+button_delete+'">Delete Last Axle</a>'+
        '</td>'+
        '</tr>';
    }

    return html + '</tbody></table></div>';
});

Handlebars.registerHelper('getHealthName', function(r, checkResolved) {
    var health = '';

    // repair
    if( r.health === app.CACHE.MAI_ANS_HEALTH.repair.id ) {
        health = 'repair'
    } else if( r.health === app.CACHE.MAI_ANS_HEALTH.safety.id ) {
        health = 'safety'
    } else if( r.is_completed === '1' ) {
        health = 'ok';
    }

    // resolved state
    if( health !== 'ok' && checkResolved && r.is_resolved === '1' ) {
        health += ' is_resolved';
    }
    
    // not applicable
    if( r.health === app.CACHE.MAI_ANS_HEALTH.na.id ) {
        health += ' health-na';
    }

    return health;
});

Handlebars.registerHelper('radio', function(choices, val, field, type) {

    var radio = '<p class="radio-container '+field+'-container">';

    if( type === 'brake' ) {
        radio += '<span class="b">What is the health status of the brakes?</span>';
    }

    // css classes for button
    var btnCSS = ( typeof(type) === 'string' ) ? 'button-'+type : '';

    $.each(choices, function(k,v){

        var label = ( $.isArray(v) ) ? v[0] : v,
            value = ( $.isArray(v) ) ? v[1] : v,
            selected = ( val === value ) ? ' selected ' : '';

        radio += '<a class="button '+btnCSS+' button-radio'+selected+'" data="'+value+'" data-field="'+field+'">'+label+'</a>';
    });

    return radio+'</p>';
});

Handlebars.registerHelper('checkbox', function(r) {

    var checkbox = '<p class="checkbox-container answer-container">';

    var choices = ( r.que.choices ) ? r.que.choices.split(',') : [],
        answers = ( r.answer ) ? r.answer.split(',') : [];

    $.each(choices, function(k,label){

        var selected = ( answers.indexOf(label) >= 0 ) ? ' selected ' : '';

        checkbox += '<a class="button button-checkbox'+selected+'" data="'+label+'" data-field="answer">'+label+'</a>';
    });

    return checkbox+'</p>';
});

Handlebars.registerHelper('radio_custom', function(r) {

    var choices = ( r.que.choices ) ? r.que.choices.split(',') : [];
    if(choices.length <= 5) {
        return Handlebars.helpers.radio(choices, r.answer, 'answer', 'radio-custom');
    }
    
    return `
        <div style="display:flex;margin-bottom:10px;justify-content:flex-start;gap:15px;">
            <input type="text" data-mai-que="${r.ts}" style="display:inline-block;" placeholder="Please select..." class="autocomplete-input button-radio" data-field="answer" value="${r.answer ?? ''}">
            <div>
                <a data-mai-que="${r.ts}" class="button button-black button-small btn-photo reset-selection">
                    <i class="fa fa-close" style="line-height:20px;vertical-align:top;margin:0;padding:0;"></i>
                </a>
            </div>
        </div>
        <div class="hidden-radios">
            ${Handlebars.helpers.radio(choices, r.answer, 'answer', 'radio-custom')}
        </div>
    `;
});

Handlebars.registerHelper('radio_health', function(r, type) {

    var choices = [],
        na = [];

    var brake = ( app.CACHE.MAI_QUE_TYP.brake === r.que.type ) ? true : false;

    $.each(app.CACHE.MAI_ANS_HEALTH, function(k,v){

        var name = v.name;

        // brake specific text
        if( brake ) {
            if( k === 'safety' ) {
                name = 'Fail';
            } if( k === 'satisfactory' ) {
                name = 'Pass';
            }
        }

        // n/a push it to last in list
        if( v.id === '4' ) {
            na.push([name,v.id]);
        } else {
            choices.push([name,v.id]);
        }
    });

    // place n/a last
    if( na.length === 1 ) {
        choices.push(na[0]);
    }

    return Handlebars.helpers.radio(choices, r.health, 'health', type);
});

Handlebars.registerHelper('radio_is_resolved', function(r) {

    var choices = [['Yes', '1'],['No', '0']];

    if( r.is_resolved === undefined || r.is_resolved === '' ) {
        r.is_resolved = '0';
    } 

    return Handlebars.helpers.radio(choices, r.is_resolved, 'is_resolved','is_resolved');
});

Handlebars.registerHelper('ifExpired', function(date_start, date_end, options) {

    var now = app.DATE.timestamp(),
        start = app.DATE.timestamp(date_start),
        end = app.DATE.timestamp(date_end);
     
    // check if now is greater than start date 
    if( now < start || now < end ) {
        return options.inverse(this);
    }

    return options.fn(this);
});

Handlebars.registerHelper('ifEditDefect', function(result, options){

    if( result && (result.hasOwnProperty('id') === false || result.is_resolved !== '1') ){
        return options.fn(this);
    }

    return options.inverse(this);
});

/**
 * Has users account been deleted?
 */
Handlebars.registerHelper('isUserDisabled', function(options){

    if( app.CACHE.USR.is_deleted === '1' || app.CACHE.USR.is_suspended === '1' || app.CACHE.USR.opr_is_suspended === '1' ){
        return options.fn(this);
    }

    return options.inverse(this);
});

Handlebars.registerHelper('isReportDisabled', function(row, options){

    if( row.hasOwnProperty('id') || row.hasOwnProperty('date_end') ) {
        return options.fn(this);
    }

    return options.inverse(this);
});
Handlebars.registerHelper('isReportEnabled', function(row, options){

    if( row && (row.id || row.date_end || app.URI[2] === 'success') ) {
        return options.inverse(this);
    }
    
    return options.fn(this);
});

Handlebars.registerHelper('ifReportDeclaration', function(progress, options){

    if( progress.completed || progress.submit ) {
        return options.fn(this);
    }

    return options.inverse(this);
});

Handlebars.registerHelper('checkRelatedIncomplete', function(rep, options){

    // not related
    if(rep && (rep.hasOwnProperty('report_id_related') === false || rep.report_id_related === '')) {
        return options.inverse(this);
    }

    // get related
    var rep_related = app.TPL.get_row('rep', rep.report_id_related);

    // related not completed
    if( rep_related && rep_related.hasOwnProperty('date_end') === false ) {
        return options.fn(this);
    }

    return options.inverse(this);
});


Handlebars.registerHelper('numberFormat', function(obj, prop){

    var no = ( !obj || !obj[prop] ) ? '0' : obj[prop];

    return no.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});


// format fake answers values
Handlebars.registerHelper('fakeAnswer', function(k, rep){
    if( !rep[k] ){
        return '-';
    }

    var que_typ_id = app.getDefaultQuestions(app.CACHE.QUE_TYP)[k].report_question_type_id;

    // percent
    if( que_typ_id === app.CACHE.QUE_TYP.PERCENT ){
        return rep[k]+'%';
    } else if( que_typ_id === app.CACHE.QUE_TYP.NUMBER ) {

        return Handlebars.helpers.numberFormat(rep, k);

    } else if( que_typ_id === app.CACHE.QUE_TYP.HEIGHT ) {

        return rep[k]+'m';
    }

    // default return
    return rep[k];
});

/**
 * Check if vehicle_type-> records is completed
 */
Handlebars.registerHelper('ifFakeAnswerComplete', function(ans, rep, options){

    if( ans.hasOwnProperty('report_question_id') || rep[ans.ts] === '' ) {
        return options.inverse(this);
    }

    return options.fn(this);
});

Handlebars.registerHelper('isRealQuestion', function(options){

    if( app.URI[2] && app.CACHE.DEFAULT_QUESTIONS[app.URI[2]] ){
        return options.inverse(this);
    }
    
    return options.fn(this);
});

Handlebars.registerHelper('isNotRealQuestion', function(options){

    if( app.URI[2] && app.CACHE.DEFAULT_QUESTIONS[app.URI[2]] ){
        return options.fn(this);
    }
    
    return options.inverse(this);
});

/**
 * Check if no value passed or value of 0
 */
Handlebars.registerHelper('ifNull', function(val, opts) {
    
    if( !val || val === '0' ){
        return opts.fn(this);
    }

    return opts.inverse(this);
});

Handlebars.registerHelper('reportButton', function(type, veh, tra){

    // report completed
    if( ( type === 'vehicle' && veh.hasOwnProperty('date_end') ) || ( type === 'trailer' &&  tra.hasOwnProperty('date_end') ) ) {
        return 'grey';
    }

    // related report started
    if(
        ( type === 'vehicle' && !veh.continue && tra && tra.continue ) ||
        ( type === 'trailer' && veh.continue && veh.hasOwnProperty('date_end') === false ) 
    ) {
        return 'grey';
    }

    return 'green';
});

// check for pictures in a report
Handlebars.registerHelper('ifReportHasPicture', function(rep, options){

    var fields = [].concat(app.TPL._get_photo_fields(4), app.TPL._get_photo_fields(4, true)),
        pic = false;

    // loop each field to check for pictures
    $.each(fields, function(k,v){
        if( rep.hasOwnProperty(v) ) {
            pic = true;
            return false;
        }
    });

    // are there any pictures
    if( pic ) {
        return options.fn(this);
    }

    return options.inverse(this);

});

Handlebars.registerHelper('plural', function(count, text) {

    // convert type to int
    count = parseInt(count, 10);

    if( count === 1 ) {
        return text;
    }

    return text + 's';
});

Handlebars.registerHelper('grammar', function(count, opts) {

    count = parseInt(count);

    // default options
    opts = ( opts && typeof(opts) === 'string' ) ? opts.split(',') : ['is', 'are'];

    if( count === 1 ) {
        return opts[0];
    }

    return opts[1];
});

/**
 * {{debug}} your application content with this
 */
Handlebars.registerHelper('debug', function(data) {
    console.log("DEBUG: this ===================");
    console.log(this);
    console.log("====================");

    if(data !== undefined) {
        console.log("DEBUG: data ==================");
        console.log(data);
        console.log("====================");
    }
});

Handlebars.registerHelper('sortBy', function(title, field, defaultDirection, sortStart, options) {

    var url = '/sort/'+field+'/',
        directionOpposite = ( defaultDirection === 'asc' ) ? 'desc' : 'asc',
        selected = '';

    // clear URL if last stage of order
    if( app.URI[1] === 'sort' && app.URI[2] === field ) {
        
        if( app.URI[3] === defaultDirection ) {
            url += directionOpposite;
        } else {
            url = '';
        }

    } else if( sortStart && !app.URI[1] ) {
        url += directionOpposite;
    } else {
        url += defaultDirection;
    }

    // ICON
    if( defaultDirection === 'asc' && app.URI[2] === field || ( !app.URI[2] && sortStart ) ) {
        //icon = '<i class="fa fa-caret-down"></i>';
        selected = 'selected';
    } else if( defaultDirection === 'desc' && app.URI[2] === field ) {
        //icon = '<i class="fa fa-caret-up"></i>';
        selected = 'selected';
    }

    return '<a class="'+selected+'" href="#'+app.HASH+url+'">'+title+'</a>';
});

/**
 * {{#regFormat RESULT.reg}}
 */
Handlebars.registerHelper('regFormat', function(reg) {

    if( !reg ) {
        return '';
    }

    //return reg.substr(0, 4) + ' ' + reg.substr(4);
    
    return reg.toUpperCase();
});

/**
 * {{#substr text}}
 */
Handlebars.registerHelper('substr', function(text, start, len) {

    if(text === undefined) {
        return text;
    }

    return text.substr(start, len);
});

/**
 * {{#replace text 'The' ''}}
 */
Handlebars.registerHelper('replace', function(text, replace, replaceWith) {
    return text.replace(replace, replaceWith);
});

/**
 * Date convert 2017-03-02 23:22 into human readable
 */
Handlebars.registerHelper('date', function(date, format, options) { 

    if( date === undefined || date === '' ) {
        return '';
    }

    // no date set get todays
    if( date === false ) {
        date = app.DATE.format('datetime');
    }

    if( format === 'convert' ) {
        return app.DATE.convert(date.substr(0,10));
    }

    if( format === true ) {

        format = 'Do MMM YYYY';

    } else if( format === 'now_dmy' ) {

        format = 'DD-MM-YYYY';

    } else if( format === 'datetime_short' ) {

        format = 'DD.MM.YY - HH:mma';
        
    } else if( format === 'date_short' ) {

        format = 'DD.MM.YY';

    } else if( format === 'datetime' ) {

        format = 'Do MMM YYYY, HH:mma';

    } else if( format === 'time' ) {

        format = 'HH:mma';
    }

    return moment(date).format(format);
});

/**
 * {{#indexOf text}}
 */
Handlebars.registerHelper('indexOf', function(haystack, needle, options) {

    console.log('hay', haystack);
    console.log('need', needle);

    if( haystack.indexOf(needle) >= 0 ) {
        return options.fn(this);
    }

    return options.inverse(this);
});

Handlebars.registerHelper('versionAdminSlug', function(tbl, row, field, thumb, is_webapp){

    const webapp = ( app.WEBAPP ) ? 'WEBAPP' : '';
    const offline = ( app.ONLINE ) ? '' : '<span class="red b">(Offline)</span>';

    return `
    <p id="logged" class="ac">
		Logged in as <span class="u">${app.CACHE.USR.email}</span> <a onclick="return confirm('are you sure you want to logout?\n\nAll unsynced data will be lost if you log out.')" href="#logout">[logout]</a><br><br>
		${webapp} Version: <a href="#admin">${app.V}${app.V_LETTER}</a>${offline}
	</p>`;
});


Handlebars.registerHelper('getFileSlug', function(tbl, row, field, thumb, is_webapp) {

    if( !field ) {
        field = 'file';
    }

    // local
    var field_l = field + '_local';
    // version
    var field_v = field + '_version';

    ///////////
    // NO FILE
    ///////////
    if( !row ||  ( !row[field] && !row[field_l] ) ) {
        return 'img/unknown_photo.png';
    }

    ///////////
    // LOCAL
    ///////////
    if( row[field_l] && row[field_l] !== '' ) {
        if(!Capacitor.isNativePlatform()) {
            return 'img/' + app.CACHE.URL_FILES[tbl] + row[field_l];
        }

        let localFile = app.FILE.convert_full_path(tbl, row[field_l]);
        return Capacitor.convertFileSrc(localFile);
    }

    ///////////
    // ONLINE
    ///////////
    
    if( is_webapp === '1' ) {
        tbl = 'web_pho';
    }

    var slug = app.CACHE.URL.uploads + app.CACHE.URL_FILES[tbl],
        v = ( row[field_v] && row[field_v] !== '0' ) ? '?v='+row[field_v] : '';

    ///////////
    // THUMB
    ///////////

    if( thumb === true ) {
        var ext = '.' + row[field].split('.').pop();
        return slug + row[field].replace(ext, '_thumb'+ext) + v;
    }
     
    return slug + row[field] + v;
});

/**
 * {{getProp 'tbl' identifier 'name'}}
 *
 * Get property/properties from app.CACHE[tbl]
 */
Handlebars.registerHelper('getProp', function(tbl, identifier, prop) {
    
    // cant really pass arrays to template so comma seperated
    if( prop.indexOf(',') !== -1 ) {
        prop = prop.split(',');
    }

    return app.cache_get_prop(tbl, identifier, prop);
});

Handlebars.registerHelper('getFormURL', function(tbl) {

    var action = ( $.isNumeric(app.URI[1]) ) ? 'edit' : 'add',
        slug = tbl + '/'+action;

    if( action === 'edit' ) {
        slug += '/'+app.URI[1];
    }

    return slug;
});

Handlebars.registerHelper('getID', function(r) {     

    if(r.hasOwnProperty('id')) {
        return r.id;
    }

    return r.ts;
});

Handlebars.registerHelper('getIDAttr', function(r) {

    if(r.hasOwnProperty('id')) {
        return 'data-id="'+r.id+'" ';
    }

    return 'data-ts="'+r.ts+'" ';
});

Handlebars.registerHelper('getIDHidden', function(r) {

    if( r === undefined ) {
        return '';
    }

    var attr = ( r.hasOwnProperty('id') ) ? 'id' : 'ts';

    return '<input type="hidden" id="'+attr+'" name="'+attr+'" value="'+r[attr]+'">';
});

/**
 * Check if record is selected with id or ts
 *
 * {{#ifSelected this item_id}{{/ifSelected}}
 */
Handlebars.registerHelper('ifSelected', function(r, val) {

    if(
        // id matches
        (r.hasOwnProperty('id') && r.id === val) ||
        // ts matches
        (r.hasOwnProperty('ts') && r.ts === val)
    ) {
        return ' selected="selected" ';
    }

    return '';
});

/**
 * {{#timepicker text}}
 */
Handlebars.registerHelper('timepicker', function(type, attr, val, min_sep) {

    var max = ( type === 'hour' ) ? 23 : 59,
        min = 0,
        time;

    // get time value
    if( val ) {

        time = ( type === 'hour' ) ? moment(val).format('HH') : moment(val).format('mm');

    } else if( attr === 'date_end_hour' ) {

        time = moment().add(1, 'hour').format('HH');

    } else {
        time = ( type === 'hour' ) ? moment().format('HH') : moment().format('mm');
    }

    var html = '<select id="'+attr+'" name="'+attr+'">';

    for(min; min <= max; min++) {

        // make sure single digits are double with leading zero
        var min_val = ( min < 10 ) ? '0' + min : min.toString(),
            selected = ( time !== undefined && time === min_val ) ? ' selected="selected" ' : '';

        html += '<option '+selected+'>'+min_val+'</option>';

        // increase min seperator
        if( type === 'min' && typeof(min_sep) === 'number' ) {
            
            // reset
            min--;

            // add different seperator
            min = min + min_sep;
        }
    }

    html += '</select>';

    return html;
});

Handlebars.registerHelper('dropdownMonth', function(field, date){

    // get first 2 chars for month from 04-01
    var month = date.substr(0,2);     

    var html = '<select name="'+field+'" id="'+field+'" class="required">';

    for(var i = 1; i <= 12; i++){
        var iStr = ( i < 10 ) ? '0' + i : i.toString(), 
            s = ( iStr === month ) ? 'selected="selected"' : '',
            monthName = moment('2017-'+iStr+'-01').format('MMMM');
        html += '<option '+s+' value="'+iStr+'">'+monthName+'</option>';
    }

    return html + '</select>';
});

Handlebars.registerHelper('dropdownDay', function(date){

    // get first 2 chars for month from 04-01
    var month = date.substr(0,2),
        day   = date.substr(3,2);
     
    var html = '',
        year = moment().format('YYYY'),
        daysInMonth = moment(year+'-'+month+'-'+day).daysInMonth();

    for(var i = 1; i <= daysInMonth; i++){

        // get full 2 digit day
        var val = ( i < 10 ) ? '0' + i : i.toString();

        // get english text for option
        var eng = moment(year + '-' + month + '-' + val).format('Do');

        // check if option is selected
        var selected = ( day === val ) ? 'selected' : '';

        // concat html
        html += '<option '+selected+' value="'+val+'">'+eng+'</option>';
    }

    return html;
});

Handlebars.registerHelper('pagingTableRows', (colspan, emptyReason) => {

    return `
        <tr class="paging" id="loading">
             <td colspan="${colspan}"><img src="img/loading-black.svg" alt="Loading"></td>
        </tr>
        <tr class="paging" id="error">
            <td colspan="${colspan}">There was an error loading this data please <a onclick="window.location.reload()" class="button button-xsmall button-green" id="btn-retry">try again</a></td>
        </tr>
        <tr class="paging" id="empty">
            <td colspan="${colspan}">${emptyReason}</td>
        </tr>`;
});

Handlebars.registerHelper('repPhotoIconCheck', (ans) => {
    if(!ans.que || ans.que.is_required_photo === '0' || (!ans.needs_photo && !ans.has_photo)) {
        return '';
    }

    const valid = '<i class="fa fa-camera"></i>';
    const invalid = '<i class="fa fa-camera-slash red"></i>';

    // Old style data checks
    if(ans.hasOwnProperty('is_valid') === false) {
        if(ans.has_photo) {
            return valid;
        }
        return invalid;
    }

    // This is for the new style of data > 4.1.7
    return ans.is_valid ? valid : invalid;
});

Handlebars.registerHelper('accWitness', (data) => {
    let btn = `
        <div style="display:flex;">
            <div class="enable-edit">
                <button class="witness-delete button-red" type="button" role="button" data-witness-id="${data.witnessId}">
                    <i class="fa fa-trash"></i>
                </button>
                <button class="witness-edit button-green" type="button" role="button" data-witness-id="${data.witnessId}">
                    <i class="fa fa-edit"></i>
                </button>
            </div>
            <div class="editing">
                <button class="witness-cancel-edit button-red" type="button" role="button" data-witness-id="${data.witnessId}">
                    <i class="fa fa-xmark"></i>
                </button>
                <button class="save-witness-edit button-green" type="button" role="button" data-witness-id="${data.witnessId}">
                    <i class="fa fa-save"></i>
                </button>
            </div>
        </div>
    `;
    
    if(app.DATA.RESULT.sync !== false || app.DATA.RESULT.is_completed === '1') {
        btn = '';
    }
    
    return `
        <div class="witness-inner">
            <div class="actions">
                ${btn}
            </div>
            <div class="details">
                <div class="grid">
                    <p class="witness-detail-header"><b>Name:</b></p>
                    <p class="witness-detail">${data.name}</p>
                    
                    <p class="witness-detail-header"><b>Phone no:</b></p>
                    <p class="witness-detail">${data.contact}</p>
                    
                    <p class="witness-detail-header"><b>Email:</b></p>
                    <p class="witness-detail">${data.email}</p>
                    
                    <p class="witness-detail-header"><b>Address:</b></p>
                    <p class="witness-detail">${data.address}</p>
                </div>
            </div>
        </div>
        <div class="edit-witness-form" data-shown="false">
            <p>Witness Name</p>
            <input type="text" class="witness_name" value="${data.name}">
            
            <p>Contact Number</p>
            <input type="text" class="witness_contact" value="${data.contact}">

            <p>Email</p>
            <input type="text" class="witness_email" value="${data.email}">
            
            <p>Address</p>
            <input type="text" class="witness_address" value="${data.address}">
        </div>
        <div class="seperator"></div>
    `;
});

Handlebars.registerHelper('defectStatusIcon', (defect, options) => {
    if(defect.is_resolved === '0' && defect.is_pending === '0' && defect.is_monitoring === '1') {
        return '<i class="fa fa-eye orange"></i>';
    }
    
    if(defect.is_resolved === '0' && defect.is_pending === '1' && defect.is_monitoring === '0') {
        return '<i class="fa fa-clock blue"></i>';
    }
    
    if(defect.is_resolved === '1' && defect.is_pending === '0' && defect.is_monitoring === '0') {
        return '<i class="fa fa-check-circle green"></i>';
    }
    
    if(defect.is_resolved === '0' && defect.is_pending === '0' && defect.is_monitoring === '0') {
        return '<i class="fa fa-exclamation-circle red"></i>';
    }
    
    console.log(defect);
    return '';
});

Handlebars.registerHelper('formatDefectNotes', (notes, options) => {
    const splitNotes = notes.split('\n\n');
    let html = ``;
    splitNotes.forEach((note) => {
        const noteSplit = note.split('\n');
        html += `
            <div class="defect-note">
                ${noteSplit.map((line, index) => {
                    if(index === 0) {
                        return '<p><b>' + line + '</b></p>';
                    }
                    return '<p>' + line + '</p>';
                }).join('\n')}
            </div>
        `;
    });
    return html;
});

Handlebars.registerHelper('generateDefectImages', (defect, options) => {
    let html = '<div class="split photos-container">';
    ['file', 'file2', 'file3', 'file4'].forEach((key) => {
        if(!defect[key] || defect[key].length === 0) {
            return;
        }
        
        if(defect.is_webapp === '1') {
            html += `<img class="img-photo img-file" src="${app.CACHE.URL.uploads}webapp_photos/${defect[key]}" alt="${key}"/>`;
            return;
        }
        
        html += `<img class="img-photo img-file" src="${app.CACHE.URL.uploads}${app.CACHE.URL_FILES[defect.tbl]}${defect[key]}" alt="${key}"/>`;
    });
    
    if(html.length === 0) {
        return '<p style="text-align:center;" class="no-data">No photos for this defect.</p>';
    }
    
    html += `</div>`;
    return html;
});
