import {Directory, Filesystem} from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";
import {FORM} from "./form";

export let FILE = {
    HAS_PERMISSION: false,

    /**
     * The initialization method that is ran before trying to access the filesystem
     * @return {Promise<void>}
     */
    start: async() => {
        if(!Capacitor.isPluginAvailable('Filesystem')) {
            return;
        }

        const hasPerms = FILE.checkPermissions();

        if(!hasPerms) {
            const newPermState = await Filesystem.requestPermissions();
            FILE.HAS_PERMISSION = newPermState.publicStorage === 'granted';
            return;
        }

        FILE.HAS_PERMISSION = true;
    },

    /**
     * Checks we have permission to access the devices filesystem
     * @return {Promise<boolean>}
     */
    checkPermissions: async () => {
        if(!Capacitor.isPluginAvailable('Filesystem')) {
            return false;
        }

        const hasPerms = await Filesystem.checkPermissions();
        return hasPerms.publicStorage === 'granted';
    },

    /**
     * Gets a file from the devices filesystem
     * @param {Filesystem.ReadFileOptions} opts the options used for getting the file you want
     * @returns {Promise<ReadFileResult>}
     * @throws {Error} Will throw an error if we do not have permissions to access the file system
     */
    readFile: async (opts) => {
        if(!FILE.HAS_PERMISSION) {
            throw new Error("Missing permission to read the file system.");
        }

        return await Filesystem.readFile(opts);
    },

    /**
     * Writes a file to the devices filesystem
     * @param {Filesystem.WriteFileOptions} opts the options used for writing the file
     * @returns {Promise<WriteFileResult>}
     * @throws {Error} Will throw an error if we do not have permissions to access the file system
     */
    writeFile: async (opts) => {
        if(!FILE.HAS_PERMISSION) {
            throw new Error("Missing permission to read the file system.");
        }

        return await Filesystem.writeFile(opts);
    },

    /**
     * Used to copy a file or directory
     * @param {Filesystem.CopyOptions} opts the options used to copy a file/directory
     * @return {Promise<CopyResult>}
     * @throws {Error} Will throw an error if we do not have permissions to access the file system
     */
    copyFile: async (opts) => {
        if(!FILE.HAS_PERMISSION) {
            throw new Error("Missing permission to read the file system.");
        }

        return await Filesystem.copy(opts);
    },

    /**
     * Get the URI for a specific file
     * @param {Filesystem.GetUriOptions} opts the options used to get the URI for the target file
     * @return {Promise<string>}
     * @throws {Error} Will throw an error if we do not have permissions to access the file system
     */
    getUri: async (opts) => {
        if(!FILE.HAS_PERMISSION) {
            throw new Error("Missing permission to read the file system.");
        }

        return (await Filesystem.getUri(opts)).uri;
    },


    /**
     * Get the URI of a file in a web friendly format for usage with img.src etc.
     * @param {string} uri the filesystem uri to get the web URI of
     * @return {string} the web friendly URI
     */
    getWebURI: (uri) => {
        return Capacitor.convertFileSrc(uri);
    },

    /**
     * Make a new directory in the filesystem
     * @param {Filesystem.MkdirOptions} opts the options used to create the directory
     * @return {Promise<void>}
     * @throws {Error} Will throw an error if we do not have permissions to access the file system
     */
    mkDir: async (opts) => {
        if(!FILE.HAS_PERMISSION) {
            throw new Error("Missing permission to read the file system.");
        }

        try {
            await Filesystem.mkdir(opts);
        } catch(err) {
            if(err.message !== 'Directory exists') {
                throw err;
            }
        }
    },

    /*
     * =============================
     * APP SPECIFIC CODE
     * =============================
     */

    AJAX: false,

    /**
     * Creates the base storage container for the app
     * @returns {Promise<void>}
     */
    create: async () => {
        console.log('app.FILE.create()');
        try {
            await FILE.start();
            if(!FILE.HAS_PERMISSION) {
                FILE.fail('Permissions for the filesystem rejected');
            }

            await FILE.mkDir({
                ...FILE.get_fs_dir(),
                recursive: true
            });

            // Create rest of containers folders
            let path_container = await FILE.getUri(FILE.get_fs_dir()),
                msg = 'SUCCESS: create_dir_container(' + path_container + ')';

            // update storage
            FILE.status(true, msg, path_container);

            // create folder for health and safety
            $.each(app.CACHE.URL_FILES, async (k,v) => {
                await FILE.create_dir_extra(v.replace('/', ''));
            });

            app.CACHE.STORAGE.setup = true;
        } catch(err) {
            FILE.fail(err.message);
        }
    },

    /**
     * Creates the extra directories within the maine storage container
     * @param {string} tbl the sub folder to create
     * @returns {Promise<void>}
     */
    create_dir_extra: async (tbl) => {
        try {
            const containerRoot = FILE.get_fs_dir();
            await FILE.mkDir({
                path: `${containerRoot.path}${tbl}`,
                directory: containerRoot.directory,
                recursive: true
            });
        } catch(err) {
            FILE.fail(err.message);
        }
    },

    move_picture: async (picture, settings, recoveryMode) => {
        //try {
            await FILE.start();
            const containerRoot = recoveryMode === true ? FILE.get_fs_dir(settings.tbl, true) : FILE.get_fs_dir(settings.tbl ?? '');
            const data = await FILE.readFile({
                path: picture.path
            });
            const filename = `${app.DATE.timestamp()}.jpg`;
            const result = await FILE.writeFile({
                path: `${containerRoot.path}/${filename}`,
                directory: containerRoot.directory,
                recursive: true,
                data: data.data,
            });
            console.log('# Saved Picture too');
            console.log(result.uri);
            console.log('############');
            settings.filename = filename;
            if(!recoveryMode) {
                app.CAMERA.save(result.uri, settings, recoveryMode);
            } else {
                return { uri: result.uri, filename: filename };
            }
        /*} catch(err) {
            console.log(`ERROR: Could not move picture: ${err}`);
        }*/
    },

    upload_pic: async (tbl, action, item, sync) => {
        console.log(tbl, action, item, sync);
        const containerRoot = FILE.get_fs_dir(tbl);
        console.log('app.FILE.upload_pic()');

        // change status
        app.FILE.AJAX = true;

        console.log('tbl', tbl);
        console.log('item', item);

        // determine row status
        let method = ( item.id ) ? '/edit/' : '/add',
            id = ( item.id ) ? item.id : '';

        try {
            const data = app.get_api_data_inline(item);
            const filename = item[item.field+'_local'];
            const finalFilename = containerRoot.path + '/' + filename;
            // shortcode object
            await FILE.start();
            let localURL = await FILE.getUri({
                    path: finalFilename,
                    directory: containerRoot.directory,
                });
            let uploadURL =  encodeURI(app.CACHE.URL.api + tbl + method + id);

            let formData = new FormData();
            Object.keys(data).forEach((key) => {
                if(!data[key]) {
                    return;
                }
                formData.append(key, data[key].toString());
            });
            const fileToUpload = await app.FILE_TRANSFER.getFileToUpload(localURL, 'jpg', item[item.field+'_local']);
            if(!fileToUpload) {
                app.FILE.upload_pic_fail({ message: 'Unexpected Error: File not found.' });
                return;
            }
            
            formData.set('upload', fileToUpload, item[item.field+'_local']);

            console.log('~~~~~~~~~~~~~~~~~~~');
            console.log('UPLOADING FILE...');
            console.log('URL', uploadURL);
            console.log('localURL', localURL);
            console.log('~~~~~~~~~~~~~~~~~~~');

            $.ajax({
                url: `${uploadURL}/?app=1`,
                method: 'POST',
                data: formData,
                processData: false,
                contentType: false,
            })
            .done((res) => app.FILE.upload_pic_win(res, tbl, action, item, sync))
            .fail((err) => app.FILE.upload_pic_fail(err));
        } catch(err) {
            app.FILE.upload_pic_fail({ message: `Something went wring when uploading image: ${err.message}` });
        }
    },

    upload_pic_win: async (res, tbl, action, item, sync) => {
        console.log('upload_picture_win()');

        // change status
        app.FILE.AJAX = false;

        // turn response into item
        console.log('response', res);

        if( res.status === 'success' ) {

            console.log('SUCCESS: File was uploaded', res, tbl);

            var identifier = item.id || item.ts;

            // clean item
            item = app.FORM.clean_json(item);

            // save to cache
            app.FORM.save(tbl, 'edit', res.data[tbl], item, true, identifier, false, sync, false, true);

            // are we syncing?
            // if so try to get next item going
            if( sync ) {

                var photo = app.SYNC.PHOTOS.pop();

                // check to see there are any photos left
                if( photo ) {

                    // upload next photo
                    await FILE.upload_pic(photo.tbl, 'edit', photo, true);

                } else {

                    console.log('No photos left to upload');

                    // sync callback
                    if( app.HASH === 'home' ){
                        app.SYNC.send_photos_win();
                    }

                    // ACOUNT
                    if( res.account ) {
                        app.check_user_account(res.account);
                    }

                    // save cache
                    app.cache_save(tbl);
                }

            } else {

                // re-check identifier
                if( res.data[tbl].id ) {
                    identifier = res.data[tbl].id;
                }

                console.log('identifier', identifier);

                // redirect
                app.FORM.redirect(tbl, false, identifier);
            }

        } else if( res.status === 'error') {
            console.error('ERROR (api):', res.errors);
        }
    },

    upload_pic_fail: (err) => {
        // change status
        app.FILE.AJAX = false;
        console.log('IMAGE UPLOAD ERROR:', err.message);
        
        if(app.HASH === 'home' ){
            app.SYNC.send_photos_fail(err.message);
        }
    },

    /**
     * Get files to download
     * @param {Array<string>} tbls all the tables that should be downloaded
     * @returns {Array<object>} An array of all the files to be downloaded
     */
    download_prepare: (tbls) => {
        let files = [];

        // loop through each passed tbls paramed
        $.each(tbls, (k,tbl) => {

            // loop through rows of tbl
            $.each(app.CACHE[tbl.toUpperCase()], (key, row) => {

                let file = FILE.download_add(tbl, row);

                if( file ) {
                    files.push(file);
                }
            });
        });

        return files;
    },

    /**
     * Gets a specific files download location and metadata for downloading it
     * @param tbl the table that the file is part of
     * @param row a row that the file is linked too
     * @returns {{file, id, dir: string, url: string, tbl}|boolean} the metadata for the file and where to download
     * it will return false if there is no file for the provided row
     */
    download_add: (tbl, row) => {
        if( row.file && row.file !== '' )  {

            let url = app.CACHE.URL.uploads + app.CACHE.URL_FILES[tbl] + row.file;

            // thumbnails
            if( app.TBL[tbl].file_thumb ) {
                let ext = '.'+url.split('.').pop();
                url = url.replace(ext, '_thumb'+ext);
            }

            return {
                'tbl': tbl,
                'file': row.file,
                'dir': encodeURI( FILE.get_fs_dir(tbl) + row.file),
                'url': encodeURI( url ),
                'id': row.id
            };
        }

        return false;
    },

    /**
     * The core function used to download files to the filesystem
     * @param files reference object to download the files
     * @returns {Promise<void>}
     */
    download: async (files) => {
        // check storage
        if( !app.CACHE.STORAGE.setup ) {
            console.log('STOP: storage not yet setup');
            // create file folders
            await FILE.create();
        }

        // ajax in progress
        if( app.check_ajax() ) {
            console.log('AJAX: in progress cannot start download().');
            return;
        }

        // check internet connection
        if( !app.check_connection() ) {
            return;
        }

        // phonegap only
        if( !app.PHONEGAP ) {
            return;
        }

        // make global object
        app.DOWNLOADS = files;

        // START DOWNLOAD
        await FILE.download_start();
    },

    download_start: async () => {
        // No files left, stop downloading
        if( app.DOWNLOADS.length === 0 ) {
            // change ajax status
            app.FILE.ajax = false;
            // hide sync icon
            app.DOM.header_sync.hide();
            console.log('download_complete()');
            return;
        }

        // change AJAX status
        app.FILE.ajax = true;

        // show sync icon
        app.DOM.header_sync.show();

        console.log(app.DOWNLOADS.length + ' Downloads remaining...');

        // remove last from array and use it
        let file = app.DOWNLOADS.pop();

        console.log('~~~~~~~~~~~~~~~~~~~');
        console.log( 'DOWNLOADING file... ', file.url, '-->', file.dir );
        console.log('~~~~~~~~~~~~~~~~~~~');

        try {
            const res = await Filesystem.downloadFile({
                url: file.url,
                path: file.dir.path,
                directory: file.dir.directory,
                recursive: true,
                method: 'GET',
            });
            FILE.download_win(res, file);
        } catch(err) {
            // change ajax status
            FILE.ajax = false;
            // hide sync icon
            app.DOM.header_sync.hide();
            console.log('download_error()', err.message, file);
            //  try to get rest of downloads
            await FILE.download_start();
        }
    },

    download_win: (success, file) => {
        console.log('download_win()', success, file);

        let data = {
            'file_local': file.file,
            'id': file.id
        };

        // save local file and then remove old one
        app.FORM.save(file.tbl, 'edit', data, data, true, file.id, false, false, false, true);
    },

    /**
     * Removes the main storage container directory
     * @returns {Promise<void>}
     */
    remove: async () => {
        try {
            const containerRoot = FILE.get_fs_dir();
            await Filesystem.rmdir({
                path: containerRoot.path,
                directory: containerRoot.directory,
                recursive: true,
            });
        } catch (err) {
            if(err.message === `Folder does not exist.`) {
                return;
            }

            console.log(`Error removing storage container: ${err.message}`);
        }
    },

    /**
     * Used to get the file system root to use for saving and reading files
     * @returns {{path: string, directory: Directory.Data}} the path from the core storage container folder
     * and the directory used for the storage container
     */
    get_fs_dir: (tbl = '', recoveryMode) => {

        if(app.CACHE.URL_FILES[tbl]) {
            tbl = app.CACHE.URL_FILES[tbl];
        }

        return {
            path: `${app.CACHE.STORAGE.container}/${tbl}`,
            directory: Directory.Data
        };
    },

    get_full_path: async () => {
        const rootDir = FILE.get_fs_dir();
        return (await Filesystem.getUri({
            path: rootDir.path,
            directory: rootDir.directory
        })).uri;
    },

    convert_full_path: (tbl = '', filename = '') => {
        if(typeof app.CACHE.STORAGE.path === "undefined") {
            return
        }

        let fullPath = app.CACHE.STORAGE.path;
        if(app.CACHE.URL_FILES[tbl]) {
            tbl = app.CACHE.URL_FILES[tbl];
        }

        if(tbl) {
            fullPath += `/${tbl}`;
        }

        fullPath += filename;
        return fullPath;
    },

    /**
     * Default fail for file
     */
    fail: function(msg)
    {
        console.log('ERROR: ' + msg);

        // update status
        FILE.status(false, msg);
    },

    /**
     * Update CACHE.STORAGE with latest information
     */
    status: function(setup, msg)
    {
        app.CACHE.STORAGE.setup = setup;
        app.CACHE.STORAGE.last_msg = msg;

        console.log('SETUP', setup);
        console.log('STATUS', msg);

        app.cache_save('storage');
    },
}